<template>
    <div class="app-container">
        <div style="width:800px;margin:0 auto">
            <el-steps :active="active" align-center>
                <el-step title="基础信息"></el-step>
                <el-step title="经营信息"></el-step>
                <el-step title="结算信息"></el-step>
            </el-steps>
        </div>
        <BaseInfo @change="getBaseInfo" :provinceList="provinceList" :updateData="baseInfo" :contactMobileNo="contactMobileNo" :update="true" v-show="active==0" :checkMsg="checkMsg"></BaseInfo>
        <BusinessInfo @change="getBusinessInfo" :legalName="legalName" :updateData="businessInfo" :update="true" v-show="active==1" :checkMsg="checkMsg"></BusinessInfo>
        <SettleInfo @change="getSettleInfo" :provinceList="provinceList" :updateData="settleInfo" :companyName="companyName" :legalName="legalName" :update="true" v-show="active==2" :checkMsg="checkMsg"></SettleInfo>
    </div>
</template>
<script>
import BaseInfo from '@/components/agentUpdateBase/baseInfo'
import BusinessInfo from '@/components/agentUpdateBase/businessInfo'
import SettleInfo from '@/components/agentUpdateBase/settleInfo'
import BenefitConfig from '@/components/agentUpdateBase/benefitConfig'
import CashBackConfig from '@/components/agentUpdateBase/cashBackConfig'
import { BasicApi,AgentApi } from '@/api'
export default {
    data() {
        return {
            active: 0,
            provinceList: [],
            parentAgentNo: 0,
            legalName: '',
            params: {},
            agentNo:'',
            contactMobileNo:'',
            companyName:'',
            baseInfo:'',
            businessInfo:'',
            settleInfo:'',
            checkMsg:''
        }
    },
    components:{
        BaseInfo,
        BusinessInfo,
        SettleInfo,
        BenefitConfig,
        CashBackConfig
    },
    created(){
       this.agentNo = this.$route.query.agentNo
       this.contactMobileNo = this.$route.query.contactMobileNo
       BasicApi.listProvinceCity()
            .then(res=>{
                if(res.success){
                    this.provinceList = res.data
                    this.$forceUpdate()
                }
            })
        AgentApi.agentDetail(this.agentNo)
            .then(res=>{
                if(res.success){
                    this.baseInfo = res.data.baseInfo
                    this.businessInfo = res.data.businessInfo
                    this.settleInfo = res.data.setterInfo
                    this.checkMsg = res.data.checkMsg
                }
            })
    },
    methods: {
        getBaseInfo(params,type){
            if(type=='next'){
                this.active ++
                this.params.baseInfo = params
                this.parentAgentNo = params.parentAgentNo ? params.parentAgentNo: '0'
                this.legalName = params.legalName
            }else if(type=='prev'){
                this.active --
                params.parentAgentNo = params.parentAgentNo == '0' ? '' :params.parentAgentNo
            }
            
        },
        getBusinessInfo(params,type){
            if(type=='next'){
                this.active ++
                this.params.businessInfo = params
                this.companyName = params.companyName
            } else if(type=='prev'){
                this.active --
            }
        },
        getSettleInfo(params,type){
            if(type=='prev'){
                this.active --
            } else if(type == 'submit'){
                this.params.setterInfo = params

                console.log(this.params)
                AgentApi.improveAgentInfo(this.agentNo,this.params)
                    .then(res=>{
                        if(res.success){
                            this.Message.success('补充资料成功')
                            this.$router.push({
                                name:'organizationQuery'
                            })
                        } else{
                           
                        }
                    })
            }
        },
        getBenefitConfig(params,type){
            if(type=='next'){
                this.active ++
                this.params.policyInfoList = params
            }else if(type=='prev'){
                this.active --
            }
        },
        getCashBackConfig(params,type){
            if(type=='submit'){
                this.params.agentActivityInfoDoList = params

                AgentApi.saveOneAgent(this.params)
                    .then(res=>{
                        if(res.success){
                            this.Message.success('新增成功')
                            this.$router.push({
                                name:'organizationQuery'
                            })
                        } else{
  
                        }
                    })
            }else if(type=='prev'){
                this.active --

            }
        },
    },
}
</script>